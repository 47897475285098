// Sentry integration
import "./sentry.js"

// Feather icons are used on some pages
// Replace() replaces [data-feather] elements with icons
import featherIcons from "feather-icons"

// Mazer internal JS. Include this in your project to get
// the sidebar running.
import "./mazer"

import Toastify from 'toastify-js'
import Swal from 'sweetalert2/dist/sweetalert2'

import "./flatpickr.js"

featherIcons.replace()

window.Toastify = Toastify;

window.Swal = Swal;
