// Sentry integration
import * as Sentry from "@sentry/browser";

/*
// ATTENTION: Only prefixed with VITE_ variables are available in the browser

console.table({
    "SENTRY_JS_DSN": import.meta.env.VITE_SENTRY_JS_DSN,
    "APP_ENV": import.meta.env.VITE_APP_ENV,
    "APP_NAME": import.meta.env.VITE_APP_NAME,
    "SENTRY_JS_ENABLE": import.meta.env.VITE_SENTRY_JS_ENABLE,
    "SENTRY_TRACES_SAMPLE_RATE_PRODUCTION": import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE_PRODUCTION,
    "SENTRY_TRACES_SAMPLE_RATE_DEV": import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE_DEV,
    "SENTRY_REPLAY_SESSION_SAMPLE_RATE_PRODUCTION": import.meta.env.VITE_SENTRY_REPLAY_SESSION_SAMPLE_RATE_PRODUCTION,
    "SENTRY_REPLAY_SESSION_SAMPLE_RATE_DEV": import.meta.env.VITE_SENTRY_REPLAY_SESSION_SAMPLE_RATE_DEV,
    "SENTRY_REPLAY_ON_ERROR_SAMPLE_PRODUCTION": import.meta.env.VITE_SENTRY_REPLAY_ON_ERROR_SAMPLE_PRODUCTION,
    "SENTRY_REPLAY_ON_ERROR_SAMPLE_DEV": import.meta.env.VITE_SENTRY_REPLAY_ON_ERROR_SAMPLE_DEV,
    "SENTRY_PROFILES_SAMPLE_RATE_PRODUCTION": import.meta.env.VITE_SENTRY_PROFILES_SAMPLE_RATE_PRODUCTION,
    "SENTRY_PROFILES_SAMPLE_RATE_DEV": import.meta.env.VITE_SENTRY_PROFILES_SAMPLE_RATE_DEV,
    "APP_URL": import.meta.env.VITE_APP_URL,
    "APP_API_URL": import.meta.env.VITE_APP_API_URL,
});
*/

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_JS_DSN,
    environment: import.meta.env.VITE_APP_ENV,
    release: import.meta.env.VITE_APP_NAME + "@" + import.meta.env.VITE_APP_ENV,
    debug: import.meta.env.VITE_SENTRY_JS_ENABLE,
    sendDefaultPii: true,
    tracesSampleRate: import.meta.env.VITE_APP_ENV === 'production' ? import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE_PRODUCTION : import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE_DEV,
    tracePropagationTargets: [
        import.meta.env.VITE_APP_URL,
        import.meta.env.VITE_APP_API_URL
    ],
    replaysSessionSampleRate: import.meta.env.VITE_APP_ENV === 'production' ? import.meta.env.VITE_SENTRY_REPLAY_SESSION_SAMPLE_RATE_PRODUCTION : import.meta.env.VITE_SENTRY_REPLAY_SESSION_SAMPLE_RATE_DEV,
    replaysOnErrorSampleRate: import.meta.env.VITE_APP_ENV === 'production' ? import.meta.env.VITE_SENTRY_REPLAY_ON_ERROR_SAMPLE_PRODUCTION : import.meta.env.VITE_SENTRY_REPLAY_ON_ERROR_SAMPLE_DEV,
    profilesSampleRate: import.meta.env.VITE_APP_ENV === 'production' ? import.meta.env.VITE_SENTRY_PROFILES_SAMPLE_RATE_PRODUCTION : import.meta.env.VITE_SENTRY_PROFILES_SAMPLE_RATE_DEV,
    integrations: [
        Sentry.browserProfilingIntegration(),
        Sentry.browserTracingIntegration(),
        Sentry.captureConsoleIntegration(),
        Sentry.debugIntegration(),
        Sentry.dedupeIntegration(),
        Sentry.extraErrorDataIntegration(),
        Sentry.httpClientIntegration(),
        Sentry.replayCanvasIntegration({
            enableManualSnapshot: true,
        }),
        Sentry.replayIntegration({
            maskAllText: true,
            blockAllMedia: true,
            workerUrl: "/build/assets/static/js/sentry-worker.min.js",
        }),
        Sentry.sessionTimingIntegration(),
    ],
});

function paintSentryCanvas() {
    const canvasRef = document.querySelector("#app");
    Sentry.getClient()
        .getIntegrationByName("ReplayCanvas")
        .snapshot(canvasRef);
}

window.testSentry = function () {
    /*
     * This is a test function to test Sentry integration.
     * $(document).ready(function () {testSentry();});
     */
    console.log("Testing Sentry");
    throw new Error("Test error from Sentry");
}
